import React from "react"
import { Helmet } from "react-helmet"

import Img from "gatsby-image"
import Layout from "../Global/Layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";


import "../../assets/styles/blogpage.scss"
import prevIcon from "../../assets/images/next.svg"

function BlogPost({ data }) {
  const { page } = data
  const { title, content, featuredImage } = page

  const blogPost = "blogpost";

  console.log(page)

  return (
    <Layout current={blogPost}>
      <Helmet>
          <title>{title} - Develescope</title>
          <meta name="description" content={page.blogPostMeta.blogExcerpt} />
        </Helmet>
      <section className="section blog-post">
      <AniLink to="/blogs" swipe direction="left">
        <a><div className="blog-back">
          <i/> 
          <h5>Back to blogs</h5>
        </div></a>
      </AniLink>
          <div className="container">
            {!!featuredImage?.node?.remoteFile?.childImageSharp && (
            <div className="post-image-wrap">
              <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid} />
            </div>
             )}
          <div className="blog-text">
            <div className="blog-info">
            <h1>{title}</h1>
            <div className="author-date">
              <h4>{page.author.node.firstName + " " +  page.author.node.lastName}</h4>
              <h4>{page.date.split("T").shift()}</h4>
            </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPost
